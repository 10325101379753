//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class NotaSalidaServices {
    Consultar(
        datos
    ){
        return http.get("notaSalida/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarDetalles(
        datos
    ) {
        return http.get("notaSalida/consultar/detalle", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    Actualizar(
        IdUsuario,
        Datos
    ){
        const datos ={
            IdUsuario: IdUsuario,
            Data: Datos
        }
        return http.post("notaSalida/actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
    }
    
  Anular(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/notaSalida/anular", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}

export default new NotaSalidaServices();