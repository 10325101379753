var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-subtitle',[_vm._v("Lista "),_c('v-btn',{attrs:{"icon":"","color":"green","loading":_vm.cargandoDatos,"disabled":_vm.cargandoDatos},on:{"click":function($event){return _vm.cargar()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCached))])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.Lista,"search":_vm.search,"items-per-page":5,"loading":_vm.cargandoDatos},scopedSlots:_vm._u([{key:"item.fechaNotaSalida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.FuncionesGenerales.formatoFecha(item.fechaNotaSalida, 1))+" ")]}},{key:"item.fechaCreacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.FuncionesGenerales.formatoFecha(item.fechaCreacion, 3))+" ")]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('BtnPdfNotaSalida',{attrs:{"id":item.id,"tipoBtn":2}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-1",on:{"click":function($event){return _vm.GetVer(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-1",on:{"click":function($event){return _vm.GetFinalizar(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":item.idEstatus == 10 ? 'success' : 'grey3'}},[_vm._v(_vm._s(_vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v("Finalizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-1",on:{"click":function($event){return _vm.GetEditar(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":item.idEstatus == 10 ? 'info' : 'grey3'}},[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-1",on:{"click":function($event){return _vm.GetAnular(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":item.idEstatus != 36 ? 'error' : 'grey3'}},[_vm._v(_vm._s(_vm.icons.mdiCancel))])],1)]}}],null,true)},[_c('span',[_vm._v("Anular")])])]}}],null,true)})],1),_c('ModalViewNotaSalida',{ref:"ModalViewNotaSalidaRef",on:{"GetAnular":_vm.GetAnular,"GetEditar":_vm.GetEditar,"GetFinalizar":_vm.GetFinalizar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }