<template>
  <row>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="GetPdf()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
  </row>
</template>
<script>
import notaSalidaServices from '@/api/servicios/NotaSalidaServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/Pdf.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    const GetPdf = () => {
      cargandoDatos.value = true

      notaSalidaServices
        .ConsultarDetalles({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.estatus == true) {
            const datoInit = response.data.datos[0]
            const cuerpoTablaDetalles = []

            datoInit.notaSalidaDetalle.forEach(item => {
              cuerpoTablaDetalles.push([
                { content: item.productoSucursal.producto.barra, styles: { halign: 'left' } },
                { content: item.productoSucursal.producto.nombre, styles: { halign: 'left' } },
                { content: item.lote ? item.lote.numeroLote : '', styles: { halign: 'left' } },
                { content: FuncionesGenerales.formatoNumeric(item.totalUnidades), styles: { halign: 'right' } },
                { content: FuncionesGenerales.formatoNumeric(item.pvp), styles: { halign: 'right' } },
                {
                  content: FuncionesGenerales.formatoNumeric(item.totalUnidades * item.pvp),
                  styles: { halign: 'right' },
                },
                {
                  content: FuncionesGenerales.formatoNumeric(item.productoSucursal.producto.tasaImpuesto.valor),
                  styles: { halign: 'right' },
                },
                {
                  content: FuncionesGenerales.formatoNumeric(
                    item.totalUnidades * item.pvp * (1 + item.productoSucursal.producto.tasaImpuesto.valor / 100),
                  ),
                  styles: { halign: 'right' },
                },
              ])
            })

            const datosInit = {
              nombreDocumento: `Nota Salida: ${datoInit.numeroDocumento}`,

              cabecera1: {
                visible: true,
              },
              titulo: {
                visible: true,
                posicion: 'center',
                texto: 'Nota Salida',
                TamanoLetras: 14,
                color: '#7B7B7B',
              },
              cabecera2: {
                visible: true,
                datos: [
                  { texto: '# ' + datoInit.numeroDocumento, TamanoLetras: 14, color: '#FF0000' },
                  { texto: 'Fecha Creacion: ' + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 1) },
                  {
                    texto:
                      'Hora Creacion: ' +
                      FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 2)
                        .replace(/\u00a0/g, '')
                        .replace(/\u202f/g, ''),
                  },
                  //{ texto: "Empleado: " +  datoInit.usuarioCrea.empleado.nombre + ' ' + datoInit.usuarioCrea.empleado.apellido },
                ],
              },
              sub1: {
                visible: true,
                datos: [
                { texto: 'Cliente: ' + (datoInit.cliente.nombre + ' ' +  datoInit.cliente.apellido).toUpperCase() },
                { texto: 'Documento: ' + datoInit.cliente.documento  },
                { texto: 'Telefono: ' + datoInit.cliente.telefono  },
                  { texto: 'Observacion: ' + datoInit.observacion }
                ],
              },
              sub2: {
                visible: true,
                datos: [ 
                { texto: 'Fecha: ' + FuncionesGenerales.formatoFecha(datoInit.fecha, 1) },
                  { texto: 'Area: ' + datoInit.area.nombre },
                  { texto: 'Estatus:' + datoInit.estatus.descripcion }, 
              
              ], 
              },
              cuerpo1: {
                visible: true,
                datos: [
                  {
                    titulo: { texto: 'Detalles', TamanoLetras: 12 },
                    styles:   {fontSize: 7,},
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'Barra', styles: { halign: 'left' } },
                        { content: 'Producto', styles: { halign: 'left' } },
                        { content: 'Lote', styles: { halign: 'left' } },
                        { content: 'Cant', styles: { halign: 'right' } },
                        { content: 'Pvp', styles: { halign: 'right' } },
                        { content: 'SubTotal', styles: { halign: 'right' } },
                        { content: 'IVA', styles: { halign: 'right' } },
                        { content: 'Total', styles: { halign: 'right' } },
                      ],
                    ],
                    body: cuerpoTablaDetalles,
                  },
                  { texto: ' '   },
                  {
                    titulo: { texto: 'Totales', TamanoLetras: 10 },
                    styles:   {fontSize: 7,},
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {
                      cant: 2,
                      id: 2,
                    },
                    head: [],
                    body: [
                      [
                        { content: 'Sub total', styles: { halign: 'left' } },
                        {
                          content:
                            FuncionesGenerales.monedaPrincipal().simbolo +
                            ' ' +
                            FuncionesGenerales.formatoNumeric(totales(datoInit).subTotal),
                          styles: { halign: 'right' },
                        },
                      ],
                      [
                        { content: 'Descuento', styles: { halign: 'left' } },
                        {
                          content:
                            FuncionesGenerales.monedaPrincipal().simbolo +
                            ' ' +
                            FuncionesGenerales.formatoNumeric(totales(datoInit).descuento),
                          styles: { halign: 'right' },
                        },
                      ],
                      [
                        { content: 'Impuesto', styles: { halign: 'left' } },
                        {
                          content:
                            FuncionesGenerales.monedaPrincipal().simbolo +
                            ' ' +
                            FuncionesGenerales.formatoNumeric(totales(datoInit).impuesto),
                          styles: { halign: 'right' },
                        },
                      ],
                      [
                        { content: 'Total', styles: { halign: 'left' } },
                        {
                          content:
                            FuncionesGenerales.monedaPrincipal().simbolo +
                            ' ' +
                            FuncionesGenerales.formatoNumeric(totales(datoInit).total),
                          styles: { halign: 'right' },
                        },
                      ],
                    ],
                  },
                ],
              },
            }

            pdfRef.value.generarPDF(datosInit)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }
    const totales = datoInit => {
      let subTotal = 0
      let impuesto = 0
      let descuento = 0
      let total = 0

      datoInit.notaSalidaDetalle.forEach(element => {
        subTotal += element.totalUnidades * element.pvp
        impuesto += element.totalUnidades * element.pvp * (element.productoSucursal.producto.tasaImpuesto.valor / 100)
      })
      total = subTotal + impuesto - descuento
      return { subTotal, impuesto, descuento, total }
    }
    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      icons: {
        mdiFilePdfBox,
      },
    }
  },
}
</script>