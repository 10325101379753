<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="900px">
            <ViewNotaSalida :VerButton="VerButton" @GetCerrarModal="GetCerrarModal" @GetAnular="GetAnular" @GetEditar="GetEditar" @GetPdf="GetPdf"
                @GetFinalizar="GetFinalizar" :idNotaSalida="idNotaSalida" :modal="true"></ViewNotaSalida>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref } from '@vue/composition-api';
import ViewNotaSalida from './ViewNotaSalida.vue';
export default {
    components: {
        ViewNotaSalida
    },
    props:{
        VerButton:{
            type:Boolean,
            default: true
        }
    },
    setup(props, context){
        const dialog = ref(false)
        const ViewNotaSalidaRef = ref(null)
        const idNotaSalida = ref(null)
        const abrir = (idNotaSalidain) => {
            idNotaSalida.value = idNotaSalidain
            dialog.value = true
        }
        const GetCerrarModal = () => {
            dialog.value = false
        }
        const GetAnular = (item) => {
            context.emit('GetAnular', item)
            dialog.value = false
        }
        const GetEditar = (item) => {

            context.emit('GetEditar', item)
            dialog.value = false
        }
        const GetPdf = (item) => {
            context.emit('GetPdf', item)
        }
        const GetFinalizar = (item) => {
            context.emit('GetFinalizar', item)
            dialog.value = false
        }

        return {
            dialog,
            abrir,
            idNotaSalida,
            GetCerrarModal,
            GetAnular,
            GetEditar,
            GetPdf,
            GetFinalizar
        }
    }
}
</script>