<template>
  <v-card>
    <v-card-title>
      <span>NotaSalida</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando el notaSalida...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-12 pb-2">
            #
            <small>{{ datos.numeroDocumento }} </small>
          </h2>
          <span class="m-0 p-0"> Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fechaCreacion, 3) }} </span>
          <br />
          <span class="m-0 p-0"> Fecha NotaSalida: {{ FuncionesGenerales.formatoFecha(datos.fecha, 1) }} </span>
          <br />
          <!--<span class="m-0 p-0"> Empleado: {{ datos.usuarioCrea.empleado.nombre + ' ' + datos.usuarioCrea.empleado.apellido}}</span> -->
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="6" cols="12">
          <span class="m-0 p-0"> Sucursal: {{ store.state.sucursalSelect.nombre }}</span>
          <br />
          <span class="m-0 p-0"> Area: {{ datos.area.nombre }}</span>

          <br />
          <h4 class="m-0 p-0">Estatus: {{ datos.estatus.descripcion }}</h4>
          <br>
          <span class="m-0 p-0"> Observacion: {{ datos.observacion }}</span>
        </v-col>
        <v-col md="6" cols="12">
          <span class="m-0 p-0"> Cliente: {{ datos.cliente.nombre }} {{ datos.cliente.apellido }}</span>
          <br> 
          <span class="m-0 p-0"> Documento: {{ datos.cliente.documento }} </span>  
          <br> 
          <span class="m-0 p-0"> Telefono: {{ datos.cliente.telefono }} </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-right">Cantidad</th>
                  <th class="text-right">Pvp</th>
                  <th class="text-right">SubTotal</th>
                  <th class="text-right">Iva</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.notaSalidaDetalle" :key="i">
                  <td>
                    <small> {{ item.productoSucursal.producto.barra }} </small>
                    <br />
                    {{ item.productoSucursal.producto.nombre }}
                    <br />
                    <small v-if="item.lote"> Lote: {{ FuncionesGenerales.camelCase(item.lote.numeroLote) }} </small>
                  </td>
                  <td class="text-right">
                    <span>{{ FuncionesGenerales.formatoNumeric(item.totalUnidades) }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ FuncionesGenerales.formatoNumeric(item.pvp) }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ FuncionesGenerales.formatoNumeric(item.totalUnidades * item.pvp) }}</span>
                  </td>

                  <td class="text-right">
                    <span>{{ item.productoSucursal.producto.tasaImpuesto.valor }}%</span>
                  </td>

                  <td class="text-right">
                    <span>{{
                      FuncionesGenerales.formatoNumeric(
                        item.totalUnidades * item.pvp * (1 + item.productoSucursal.producto.tasaImpuesto.valor / 100),
                      )
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="8"> </v-col>
        <v-col cols="12" sm="6" md="4" lg="4">
          <h5 class="ml-4">Totales</h5>
          <v-simple-table dense>
            <template>
              <tbody>
                <tr>
                  <td>Sub Total</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(totales().subTotal, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>Descuento</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(totales().descuento, 2) }}
                  </td>
                </tr>
                <tr>
                  <td>Impuesto</td>
                  <td class="text-right">
                    <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                    {{ FuncionesGenerales.formatoNumeric(totales().impuesto, 2) }}
                  </td>
                </tr>

                <tr>
                  <td>
                    <h4><span class="primary--text">Total</span></h4>
                  </td>
                  <td class="text-right">
                    <h4>
                      <span class="primary--text">
                        <span> {{ FuncionesGenerales.monedaPrincipal().simbolo }}</span>
                        {{ FuncionesGenerales.formatoNumeric(totales().total, 2) }}
                      </span>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdfNotaSalida :id="idNotaSalida" :tipoBtn="1"></BtnPdfNotaSalida>
          <v-btn
            v-if="VerButton == true"
            color="primary"
            class="ml-2"
            small
            :disabled="datos.idEstatus != 10"
            @click="GetEditar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiPencil }}
            </v-icon>
            Editar
          </v-btn>
          <v-btn
            v-if="VerButton == true"
            color="primary"
            class="ml-2"
            small
            :disabled="datos.idEstatus != 10"
            @click="GetFinalizar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiCheck }}
            </v-icon>
            Finalizar
          </v-btn>
          <v-btn
            v-if="VerButton == true"
            color="error"
            class="ml-2"
            small
            :disabled="datos.idEstatus == 13"
            @click="GetAnular(datos)"
          >
            <v-icon left small> {{ icons.mdiBlockHelper }} </v-icon>Anular
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import NotaSalidaServices from '@/api/servicios/NotaSalidaServices'
import BtnPdfNotaSalida from './BtnPdfNotaSalida.vue'
import store from '@/store'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox } from '@mdi/js'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdfNotaSalida,
  },
  props: {
    idNotaSalida: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const cargando = ref(false)
    const datos = ref({})

    onBeforeMount(() => {
      cargar()
    })

    watch(props, () => {
      cargar()
    })

    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const totales = () => {
      let subTotal = 0
      let impuesto = 0
      let descuento = 0
      let total = 0

      datos.value.notaSalidaDetalle.forEach(element => {
        subTotal += element.totalUnidades * element.pvp
        impuesto += ( element.totalUnidades * element.pvp) *  (element.productoSucursal.producto.tasaImpuesto.valor/100)
      })
      total = subTotal + impuesto - descuento
      return { subTotal, impuesto, descuento, total }
    }
    const cargar = () => {
      cargando.value = true
      NotaSalidaServices.ConsultarDetalles({ id: props.idNotaSalida })
        .then(response => {
          if ((response.data.estatus = true)) {
            if (response.data.datos != null && response.data.datos.length > 0) {
              console.log('ConsultarDetalles')
              console.log(response.data.datos)
              datos.value = response.data.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }

    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      store,
      cargando,
      totales
    }
  },
}
</script>